import Vue from "vue";

export default {
  setData(state, data) {
    state.inputData = data;
    setTimeout(() => {
      state.loadingScreen = false;
    }, 100);
  },
  setYear(state) {
    state.year = new Date().getFullYear();
  },
  updateBurger: state => {
    state.isNavOpen = !state.isNavOpen;
  },
  setLanguage(state, language) {
    state.language = language;
  },
  setCookieVisible(state, boolean) {
    state.cookieVisible = boolean;
  },
  setCurrentRoute(state, currentRoute) {
    state.currentRoute = currentRoute;
  },
  setFieldValue: (state, { field, value }) => {
    Vue.set(state.bookingForm, field, value);
  }
};
