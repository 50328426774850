<template>
  <div class="page__internal page__gallery">
    <flexi-top-card :inputData="inputData.internalPages[4].top_card" />

    <!-- Galery Section // All Sections inside -->
    <flexi-wrapper
      :inputData="inputData.internalPages[4].sections[0]"
      id="filtered-sections"
    >
      <template v-slot:title>
        <h2
          v-text="inputData.internalPages[4].sections[0].title"
          data-aos="fade-up"
          data-aos-duration="1000"
        />
      </template>
      <template v-slot:content>
        <div class="flexi-wrapper__section--content--titles">
          <div
            v-for="(value, key) in inputData.internalPages[4].sections"
            :key="key"
            class="flexi-wrapper__section--content--titles--item"
            :class="{ active: itemKey === key }"
            @click="selectActiveClass(key)"
          >
            <span
              v-text="value.title"
              data-aos="fade-up"
              data-aos-duration="1000"
              class="flexi-wrapper__section--content--titles--item--title"
            />
          </div>
        </div>
        <div class="flexi-wrapper__section--content--images">
          <div
            v-for="(value, key) in inputData.internalPages[4].sections"
            :key="key"
            class="flexi-wrapper__section--content--images--content"
          >
            <div
              v-for="(widget, imgKey) in value.widgets"
              :key="imgKey"
              class="flexi-wrapper__section--content--images--content--item"
              v-show="itemKey === key"
            >
              <expandable-image
                :src="widget.img"
                class="
              flexi-wrapper__section--content--images--content--item--img
              "
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Last Card -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.internalPages[4].sections[10]"
    >
      <template v-slot:title="slotProps">
        <div class="lastCard-title">
          <a href="/">
            <h2
              v-text="slotProps.inputData.title"
              :data-aos="currentResolution >= 991 ? 'fade-up' : ''"
              data-aos-duration="900"
              data-aos-offset="300"
            />
          </a>
        </div>
        <div class="lastCard-subtitle" v-text="slotProps.inputData.subTitle" />
        <p
          class="flexi-wrapper__section--paragraph"
          v-html="slotProps.inputData.description"
          :data-aos="currentResolution >= 991 ? 'fade-up' : ''"
          data-aos-duration="900"
          data-aos-offset="300"
        />
      </template>
      <template v-slot:content="slotProps">
        <flexi-column
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          :inputData="value"
          :options="lastCardSection.options"
          :data-aos="
            currentResolution >= 991
              ? (key === 0 && 'fade-right') ||
                (key === 1 && 'fade-down') ||
                (key === 2 && 'fade-left')
              : ''
          "
          data-aos-duration="1500"
        />
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "gallery",
  data() {
    return {
      itemKey: 1,
      lastCardSection: {
        options: {
          img: true,
          date: false,
          description: false,
          paragraphOn: false,
          subTitle: true,
          button: false
        }
      }
    };
  },
  computed: {
    ...mapGetters(["inputData"])
  },
  mounted() {
    this.animateTopCard();
  },
  methods: {
    selectActiveClass(key) {
      this.itemKey = key;
    }
  }
};
</script>

<style></style>
