import Vue from "vue";
import VueRouter from "vue-router";

//home page
import Home from "../views/Home.vue";

//internal pages
import RoomsSuite from "../views/RoomsSuite";
import Activities from "../views/Activities.vue";
import Sightseeing from "../views/Sightseeing";
import Contact from "../views/Contact.vue";
import Gallery from "../views/Gallery.vue";
import Disclaimer from "../views/Disclaimer.vue";
import DataProtection from "../views/DataProtection.vue";

//page not fount
import PageNotFound from "../views/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  //Home Page
  {
    path: "/",
    name: "home",
    component: Home
  },

  //internal pages
  {
    path: "/activities",
    name: "activities",
    component: Activities
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact
  },
  {
    path: "/data-protection",
    name: "data-protection",
    component: DataProtection
  },
  {
    path: "/legal-notice",
    name: "legal-notice",
    component: Disclaimer
  },
  {
    path: "/gallery",
    name: "gallery",
    component: Gallery
  },
  {
    path: "/rooms-suite",
    name: "rooms-suite",
    component: RoomsSuite
  },
  {
    path: "/sightseeing",
    name: "sightseeing",
    component: Sightseeing
  },

  //page not found
  {
    path: "*",
    component: PageNotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
