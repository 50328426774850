<template>
  <div class="page__internal page__contact" id="top-card">
    <!-- First section -->
    <flexi-wrapper
      class="flexi-common-wrapper"
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[0]"
    >
      <template v-slot:title="slotProps">
        <h2
          class="flexi-common-wrapper__title"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-common-wrapper__content">
          <p
            class="flexi-common-wrapper__content--description"
            v-html="slotProps.inputData.description"
          />
        </div>
        <div class="flexi-common-wrapper__form">
          <form
            @keyup.enter="validateContactForm"
            novalidate
            data-vv-scope="contact-form"
            id="contact-form"
            class="contact-form"
          >
            <div class="contact-form__item">
              <input
                @paste.prevent
                v-model="contactForm.firstName"
                v-validate="{ required: true, min: 2, max: 30 }"
                :class="{
                  'valid-input': errors.has('contact-form.First Name')
                }"
                class="contact-form__item--input"
                type="text"
                name="First Name"
                placeholder="Name"
                required
              />
              <validation-message
                :message="errors.first('contact-form.First Name')"
                :show="errors.has('contact-form.First Name')"
              />
            </div>
            <div class="contact-form__item">
              <input
                @paste.prevent
                v-model="contactForm.email"
                v-validate="{ required: true, email: true }"
                :class="{ 'valid-input': errors.has('contact-form.Email') }"
                class="contact-form__item--input"
                type="text"
                name="Email"
                placeholder="Email"
                required
              />
              <validation-message
                :message="errors.first('contact-form.Email')"
                :show="errors.has('contact-form.Email')"
              />
            </div>
            <div class="contact-form__item">
              <input
                @paste.prevent
                v-model="contactForm.phone"
                v-validate="{ required: true, numeric: true, min: 6, max: 15 }"
                :class="{ 'valid-input': errors.has('contact-form.Phone') }"
                class="contact-form__item--input"
                type="text"
                name="Phone"
                placeholder="Phone"
                required
              />
              <validation-message
                :message="errors.first('contact-form.Phone')"
                :show="errors.has('contact-form.Phone')"
              />
            </div>
            <div class="contact-form__item">
              <textarea
                @paste.prevent
                v-model="contactForm.comment"
                v-validate="{ required: true, min: 2, max: 500 }"
                :class="{ 'valid-input': errors.has('contact-form.Comment') }"
                class="contact-form__item--input__textarea"
                name="Comment"
                placeholder="Comments"
                required
              />
              <validation-message
                :message="errors.first('contact-form.Comment')"
                :show="errors.has('contact-form.Comment')"
              />
            </div>
            <label
              >I agree to the terms of this
              <a href="/data-protection" target="_blank">Privacy Policy</a>
              <input
                v-model="agreedTermsInput"
                type="checkbox"
                id="checked"
                name="Checked"
                v-validate="{ required: true }"
                required
              />
              <span class="checkmark" />
            </label>
            <div class="agreed-terms">
              <validation-message
                :message="errors.first('contact-form.Checked')"
                :show="errors.has('contact-form.Checked')"
              />
            </div>
            <div class="flexi-button-send">
              <a
                @click="validateContactForm"
                v-text="slotProps.inputData.buttonText"
              />
            </div>
          </form>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Second section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[2]"
    >
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content-left">
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
            class="flexi-wrapper__section--content-left--img"
            data-aos="fade-up"
            data-aos-duration="900"
            data-aos-offset="300"
          />
          <div class="flexi-wrapper__section--content-left--second-img"></div>
          <img
            :src="slotProps.inputData.widgets[0].img"
            :alt="slotProps.inputData.widgets[0].title"
            class="flexi-wrapper__section--content-left--second--img"
            data-aos="fade-up"
            data-aos-duration="900"
            data-aos-offset="300"
          />
        </div>
        <div class="flexi-wrapper__section--content-right">
          <div class="flexi-wrapper__section--content-right__position">
            <p
              v-text="slotProps.inputData.subTitle"
              class="flexi-wrapper__section--content-right__position--subtitle"
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-offset="300"
            />
            <h2
              v-text="slotProps.inputData.title"
              class="flexi-wrapper__section--content-right__position--title"
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-offset="300"
            />
            <p
              v-html="slotProps.inputData.description"
              class="flexi-wrapper__section--content-right__position--paragraph"
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-offset="300"
            />
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- third section slider -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[2]"
      data-aos="fade-up"
      data-aos-duration="900"
      data-aos-offset="200"
    >
      <template v-slot:content="slotProps">
        <flexi-slider
          :inputData="slotProps.inputData.widgets"
          :transitionName="'fade'"
          :transitionMode="'out-in'"
          :nextButtonText="''"
          :prevButtonText="''"
        >
          <template v-slot:item="{ computedIndex, currentItem }">
            <div class="flexi-slider__wrapper--img">
              <img :src="currentItem.img" />
            </div>
            <div class="flexi-slider__wrapper--counter">
              <span v-text="formatNumber(computedIndex)" />
              <span v-text="'/'" />
              <span v-text="formatNumber(slotProps.inputData.widgets.length)" />
            </div>
          </template>
        </flexi-slider>
      </template>
    </flexi-wrapper>

    <!-- Last card section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[1]"
    >
      <template v-slot:title="slotProps">
        <div class="lastCard-title">
          <a href="/">
            <h2
              v-text="slotProps.inputData.title"
              :data-aos="currentResolution >= 991 ? 'fade-up' : ''"
              data-aos-duration="900"
              data-aos-offset="300"
            />
          </a>
        </div>
        <div class="lastCard-subtitle" v-text="slotProps.inputData.subTitle" />
        <p
          class="flexi-wrapper__section--paragraph"
          v-html="slotProps.inputData.description"
          :data-aos="currentResolution >= 991 ? 'fade-up' : ''"
          data-aos-duration="900"
          data-aos-offset="300"
        />
      </template>
      <template v-slot:content="slotProps">
        <flexi-column
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          :inputData="value"
          :options="lastCardSection.options"
          :data-aos="
            currentResolution >= 991
              ? (key === 0 && 'fade-right') ||
                (key === 1 && 'fade-down') ||
                (key === 2 && 'fade-left')
              : ''
          "
          data-aos-duration="1500"
        />
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "contact",
  computed: {
    ...mapGetters(["inputData"])
  },
  data() {
    return {
      lastCardSection: {
        options: {
          img: true,
          date: false,
          description: false,
          paragraphOn: false,
          subTitle: true,
          button: false
        }
      }
    };
  },
  mounted() {
    this.animateTopCard();
    setTimeout(() => {
      this.smoothScroll("top-card");
    }, 1300);
  },
  methods: {
    formatNumber(id) {
      return id < 10 ? "0" + id.toString() : id.toString();
    }
  }
};
</script>

<style></style>
