const apiUrl = process.env.VUE_APP_API_URL;
const bookApiUrl = process.env.VUE_APP_API_URL_BOOK;
const contactApiUrl = process.env.VUE_APP_API_URL_CONTACT;
const token = process.env.VUE_APP_SECRET;
const tokenNl = process.env.VUE_APP_SECRET_NL;
const tokenMk = process.env.VUE_APP_SECRET_MK;

export default {
  fetchData(language) {
    return new Promise(resolve => {
      var selectedToken;
      if (language === "EN") {
        selectedToken = token;
      } else if (language === "NL") {
        selectedToken = tokenNl;
      } else {
        selectedToken = tokenMk;
      }
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.addEventListener("readystatechange", function() {
        if (this.readyState === 4) {
          resolve(JSON.parse(this.responseText));
        }
      });
      xhr.open("POST", `${apiUrl}`);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.setRequestHeader("Authorization", `Bearer ${selectedToken}`);
      xhr.setRequestHeader("Accept", "*/*");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.send();
    });
  },
  bookDate(form) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(form),
        redirect: "follow"
      };

      fetch(`${bookApiUrl}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  },
  sendContactMail(form) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(form),
        redirect: "follow"
      };

      fetch(`${contactApiUrl}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  }
};
