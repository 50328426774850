import client from "api-client";

export default {
  fetchData({ state, commit }) {
    return client.fetchData(state.language).then(data => {
      commit("setData", data);
    });
  },
  setYear({ commit }) {
    commit("setYear");
  },
  setBurger({ commit }) {
    commit("updateBurger");
  },
  setLanguage({ commit }, language) {
    client.fetchData(language).then(data => {
      commit("setData", data);
    });
    commit("setLanguage", language);
  },
  bookDate({ commit }, form) {
    console.log(commit);
    return new Promise(resolve => {
      client.bookDate(form).then(response => {
        resolve(response);
      });
    });
  },
  sendContactMail({ commit }, form) {
    console.log(commit);
    return new Promise(resolve => {
      client.sendContactMail(form).then(response => {
        resolve(response);
      });
    });
  },
  setCookieVisible({ commit }, payload) {
    commit("setCookieVisible", payload);
  },
  setCurrentRoute({ commit }, payload) {
    commit("setCurrentRoute", payload);
  }
};
