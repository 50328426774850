import Vue from "vue";
import FlexiSlider from "../components/FlexiSlider.vue";
import FlexiModal from "../components/FlexiModal.vue";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions } from "vuex";
import { mapFields } from "../store/helpers";
import { gsap, TimelineMax, Power2 } from "gsap";

gsap.registerPlugin(TimelineMax, Power2);

Vue.mixin({
  components: {
    FlexiSlider,
    FlexiModal,
    datepicker: Datepicker,
    vSelect
  },
  computed: {
    ...mapFields(["bookingForm"])
  },
  data() {
    return {
      modal: null,
      modalArrayData: [],
      currentModalId: null,
      currentResolution: window.innerWidth,
      guestList: [
        {
          name: 1
        },
        {
          name: 2
        },
        {
          name: 3
        }
      ],
      bookingTypes: [
        {
          name: "Studio with Lake View"
        },
        {
          name: "Apartment with Lake View"
        },
        {
          name: "Triple Room with Lake View"
        },
        {
          name: "Double Room with Lake View"
        },
        {
          name: "Double Room"
        }
      ],
      contactForm: {
        firstName: null,
        email: null,
        phone: null,
        comment: null
      },
      agreedTermsInput: false,
      processingBooking: false
    };
  },
  methods: {
    ...mapActions(["setCookieVisible", "bookDate", "sendContactMail"]),
    openModal(data, array, id, index) {
      var self = this;
      self.modalArrayData = array;
      self.modal = data;
      self.currentModalId = index;
      location.href = "#services";
    },
    goToRoute(route) {
      this.$router.push(route);
    },
    goToRouteFromObject(data) {
      this.$router.push(data.buttonLink);
    },
    smoothScroll(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    formatNumber(id) {
      return id < 10 ? "0" + id.toString() : id.toString();
    },
    changeDepartureDate(data) {
      this.bookingForm.departure = new Date(data.getTime() + 586400000);
    },
    openDatePicker(ref) {
      this.$refs[ref].isOpen
        ? this.$refs[ref].close()
        : this.$refs[ref].showCalendar();
    },
    openBookingModal() {
      if (this.overflowHidden === true) {
        this.setInitBurger();
        location.href = "#booking";
      } else {
        location.href = "#booking";
      }
    },
    reserveRoom() {
      this.$validator.validateAll("form").then(result => {
        if (result) {
          this.processingBooking = true;
          this.bookDate(this.bookingForm).then(result => {
            if (result) {
              setTimeout(() => {
                this.setCookieVisible(true);
                location.href = "#";
                this.processingBooking = false;
                this.resetBookingForm();
              }, 1500);
            }
          });
        } else {
          this.processingSubscription = false;
        }
      });
    },
    validateContactForm() {
      this.$validator.validateAll("contact-form").then(result => {
        if (result) {
          this.sendContactMail(this.bookingForm).then(result => {
            if (result) {
              this.resetContactForm();
            }
          });
        }
      });
    },
    resetBookingForm() {
      this.bookingForm.arrival = new Date();
      this.bookingForm.departure = new Date(new Date().getTime() + 586400000);
      this.bookingForm.guests = 1;
      this.bookingForm.type = "Studio with Lake View";
      this.bookingForm.name = "";
      this.bookingForm.lastName = "";
      this.bookingForm.email = "";
      this.bookingForm.age = "";
      this.bookingForm.country = "";
      this.bookingForm.phone = "";
      this.$nextTick(() => {
        this.errors.clear();
        this.$nextTick(() => {
          this.$validator.reset();
        });
      });
    },
    resetContactForm() {
      this.contactForm.firstName = "";
      this.contactForm.email = "";
      this.contactForm.phone = "";
      this.contactForm.comment = "";
      this.agreedTermsInput = false;
      this.$nextTick(() => {
        this.errors.clear();
        this.$nextTick(() => {
          this.$validator.reset();
        });
      });
    },
    animateTopCard() {
      const logo = document.querySelector(".header-logo");
      const nav_button = document.querySelector(".nav-button");
      const title = document.querySelector(".top-card__title");

      const flag_one = document.querySelector("#flag-one");
      const flag_two = document.querySelector("#flag-two");
      const flag_three = document.querySelector("#flag-three");

      const booking_wrapper = document.querySelector(".flexi-booking");

      const tl = new TimelineMax();
      tl.fromTo(logo, { y: -50 }, { y: 0, duration: 0.35 })
        .fromTo(
          nav_button,
          { opacity: 0, x: 20 },
          { opacity: 1, x: 0, ease: Power2.easeInOut, duration: 0.5 }
        )
        .fromTo(
          flag_one,
          { opacity: 0, x: 50 },
          { opacity: 1, x: 0, ease: Power2.easeInOut, duration: 0.4 }
        )
        .fromTo(
          flag_two,
          { opacity: 0, x: 50 },
          { opacity: 1, x: 0, ease: Power2.easeInOut, duration: 0.4 }
        )
        .fromTo(
          flag_three,
          { opacity: 0, x: 50 },
          { opacity: 1, x: 0, ease: Power2.easeInOut, duration: 0.4 }
        )
        .fromTo(
          title,
          { opacity: 0, y: -50 },
          { opacity: 1, y: 0, ease: Power2.easeInOut, duration: 0.7 },
          "-=0.55"
        )
        .fromTo(
          booking_wrapper,
          { y: 100 },
          { y: 0, ease: Power2.easeInOut, duration: 0.7 },
          "-=0.25"
        );
    }
  }
});
