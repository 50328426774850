import Vue from "vue";

Vue.directive("lowercase", {
  update: el => {
    if (!el.value) {
      return "";
    }
    el.value = el.value.toLowerCase().trim();
  }
});
