export default {
  inputData: state => state.inputData,
  year: state => state.year,
  isNavOpen: state => state.isNavOpen,
  loadingScreen: state => state.loadingScreen,
  language: state => state.language,
  cookieVisible: state => state.cookieVisible,
  currentRoute: state => state.currentRoute,
  bookingForm: state => state.bookingForm
};
