/*
    <!-- infinite loop -->
        <vue-typed-js :strings="['awesome', 'brilliant']" :loop="true" @onComplete="doSmth()">
        <h2>We are a <span class="typing"></span> company!</h2>
        </vue-typed-js>

        <!-- type pausing -->
        <vue-typed-js :strings="['This is text ^1000 which gets paused for 1 second', 'wow, interesting']">
        <h2 class="typing"></h2>
        </vue-typed-js>

        <!-- output html -->
        <vue-typed-js :strings="['<p>Paragraph</p>', '<span>Span</span>']" :contentType="'html'">
        <h2 class="typing"></h2>
        </vue-typed-js>

        See more at: https://github.com/Orlandster/vue-typed-js#examples
*/

import Vue from "vue";
import VueTypedJs from "vue-typed-js";
Vue.use(VueTypedJs);
