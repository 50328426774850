<template>
  <div class="page__internal page__disclaimer" id="top-card">
    <flexi-wrapper
      class="flexi-common-wrapper"
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[5].sections[0]"
    >
      <template v-slot:title="slotProps">
        <h2
          class="flexi-common-wrapper__title"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-common-wrapper__content">
          <p
            class="flexi-common-wrapper__content--description"
            v-html="slotProps.inputData.description"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Last Card -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[4]"
    >
      <template v-slot:title="slotProps">
        <div class="lastCard-title">
          <a href="/">
            <h2
              v-text="slotProps.inputData.title"
              :data-aos="currentResolution >= 991 ? 'fade-up' : ''"
              data-aos-duration="900"
              data-aos-offset="300"
            />
          </a>
        </div>
        <div class="lastCard-subtitle" v-text="slotProps.inputData.subTitle" />
        <p
          class="flexi-wrapper__section--paragraph"
          v-html="slotProps.inputData.description"
          :data-aos="currentResolution >= 991 ? 'fade-up' : ''"
          data-aos-duration="900"
          data-aos-offset="300"
        />
      </template>
      <template v-slot:content="slotProps">
        <flexi-column
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          :inputData="value"
          :options="lastCardSection.options"
          :data-aos="
            currentResolution >= 991
              ? (key === 0 && 'fade-right') ||
                (key === 1 && 'fade-down') ||
                (key === 2 && 'fade-left')
              : ''
          "
          data-aos-duration="1500"
        />
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "legal-notice",
  computed: {
    ...mapGetters(["inputData"])
  },
  data() {
    return {
      lastCardSection: {
        options: {
          img: true,
          date: false,
          description: false,
          paragraphOn: false,
          subTitle: true,
          button: false
        }
      }
    };
  },
  mounted() {
    this.animateTopCard();
  }
};
</script>

<style></style>
