<template>
  <div id="app">
    <div>
      <!-- LOGO & Header -->
      <flexi-header
        :inputData="inputData.navigationPages"
        :isNavOpen="isNavOpen"
        id="flexi-header"
      >
        <template v-slot:logo>
          <span
            @click="clickedLogo"
            class="header-logo"
            :class="{ 'hide-logo': overflowHidden }"
          >
            <img
              :class="{ 'show-white-logo': overflowHidden }"
              src="~@/assets/img/logo_white.png"
            />
          </span>
        </template>
        <template v-slot:flexi-burger>
          <flexi-burger
            :class="{
              'black-burger':
                currentRoute === 'contact' ||
                currentRoute === 'legal-notice' ||
                currentRoute === 'data-protection'
            }"
            @toggle-burger="setInitBurger"
            :isNavOpen="isNavOpen"
          />
          <button
            @click="openBookingModal"
            class="nav-button"
            :class="{
              'nav-button-opposite':
                currentRoute === 'contact' ||
                currentRoute === 'legal-notice' ||
                currentRoute === 'data-protection'
            }"
          >
            <span class="nav-button__text" v-text="'BOOK NOW'" />
          </button>
          <div class="header-languages">
            <!-- <img
							v-text="'EN'"
							@click="changeLanguage('EN')"
							id="flag-one"
							src="~@/assets/img/usFlag.png"
						/> -->
            <!-- <img
							v-text="'NL'"
							@click="changeLanguage('NL')"
							id="flag-two"
							src="~@/assets/img/nlFlag.png"
						/>
						<img
							v-text="'MK'"
							@click="changeLanguage('MK')"
							id="flag-three"
							src="~@/assets/img/mkFlag.png"
						/> -->
          </div>
        </template>
        <template v-slot:flexi-bar>
          <div class="sidebar">
            <transition name="fade">
              <div v-if="isNavOpen" class="sidebar-panel">
                <div class="sidebar-division">
                  <ul class="sidebar-panel-nav sidebar-panel--left">
                    <li
                      v-for="(value, key) in inputData.navigationPages"
                      :key="key"
                      @click="setInitBurger"
                    >
                      <router-link :to="value.href" v-text="value.name" />
                    </li>
                  </ul>
                  <ul class="sidebar-panel-nav sidebar-panel--right">
                    <div class="menu">
                      <li
                        v-for="(value, key) in rightMenu"
                        :key="key"
                        @click="setInitBurger"
                      >
                        <a
                          @click="
                            chooseRedirection(
                              value.href,
                              value.isPage,
                              value.bookPage
                            )
                          "
                          v-text="value.name"
                        />
                      </li>
                    </div>
                    <div class="contact">
                      <small>Find us</small>
                      <span> Dolno Konjsko, 6000 Ohrid Macedonia </span>
                      <small class="small-contact">Contact</small>
                      <br />
                      <span> +389 46 277 289 info@vilagrozdan.eu </span>
                    </div>
                  </ul>
                </div>
                <flexi-small-widget :inputData="inputData.homePage">
                  <template v-slot:widget-social>
                    <div class="flexi-small-widget__parent">
                      <span
                        class="flexi-small-widget_subtitle"
                        v-text="'Follow us:'"
                      />
                      <div class="flexi-small-widget__social-icons">
                        <a
                          v-for="(value, key) in inputData.homePage.last_card
                            .social"
                          :key="key"
                          :href="value.href"
                          target="_blank"
                        >
                          <i class="icon" :class="value.class" />
                        </a>
                      </div>
                    </div>
                  </template>
                </flexi-small-widget>
              </div>
            </transition>
          </div>
        </template>
      </flexi-header>
      <flexi-visible when-hidden="#flexi-header">
        <a @click.prevent="smoothScroll('top-card')">
          <div class="arrow-down">
            <div class="svg" />
          </div>
        </a>
      </flexi-visible>
      <!-- Navigation -->
      <!-- Router View + Transition -->
      <vue-page-transition name="overlay-up-down">
        <router-view />
      </vue-page-transition>
      <flexi-cookie v-if="cookieVisible">
        <template v-slot:content>
          <a class="close" @click="setCookieVisible(false)" />
          <div class="flexi-cookie__content">
            <div class="flexi-cookie__content--info">
              <p class="paragraph">
                Thank You for your Reservation! We will write you an
                confirmation email as soon as possible. Regards, Vila Grozdan
              </p>
            </div>
            <div class="flexi-cookie__content--links">
              <font-awesome-icon icon="check-circle" size="2x" />
            </div>
          </div>
        </template>
      </flexi-cookie>

      <flexi-modal :name="'booking'">
        <template v-slot:content>
          <div class="flexi-modal__content">
            <div class="flexi-modal__content--header">
              <img src="~@/assets/img/logo_white.png" />
            </div>
            <div class="flexi-modal__content--form">
              <div class="flexi-modal__description">
                <h3>Enter your details:</h3>
              </div>
              <div class="flexi-modal__input">
                <form @submit.prevent id="form" novalidate data-vv-scope="form">
                  <div class="input-wrapper">
                    <input
                      v-model="bookingForm.name"
                      v-validate="{
                        required: true,
                        max: 100
                      }"
                      name="Name"
                      type="text"
                      placeholder="First name"
                      autocomplete="off"
                      @paste.prevent
                      :class="{ 'valid-input': errors.has('form.Name') }"
                      class="input-wrapper__input"
                      required
                    />
                    <validation-message
                      :message="errors.first('form.Name')"
                      :show="errors.has('form.Name')"
                    />
                  </div>

                  <div class="input-wrapper">
                    <input
                      v-model="bookingForm.lastName"
                      v-validate="{
                        required: true,
                        max: 150
                      }"
                      name="Last Name"
                      type="text"
                      placeholder="Last name"
                      autocomplete="off"
                      @paste.prevent
                      :class="{ 'valid-input': errors.has('form.Last Name') }"
                      class="input-wrapper__input"
                      required
                    />
                    <validation-message
                      :message="errors.first('form.Last Name')"
                      :show="errors.has('form.Last Name')"
                    />
                  </div>

                  <div class="input-wrapper">
                    <input
                      v-model="bookingForm.email"
                      v-validate="{
                        required: true,
                        email: true
                      }"
                      v-lowercase
                      name="E-mail"
                      type="text"
                      placeholder="E-mail"
                      autocomplete="off"
                      @paste.prevent
                      :class="{ 'valid-input': errors.has('form.E-mail') }"
                      class="input-wrapper__input"
                      required
                    />
                    <validation-message
                      :message="errors.first('form.E-mail')"
                      :show="errors.has('form.E-mail')"
                    />
                  </div>

                  <div class="input-wrapper">
                    <input
                      v-model="bookingForm.age"
                      v-validate="{
                        required: true,
                        numeric: true,
                        min: 2,
                        max: 2
                      }"
                      name="Age"
                      type="text"
                      placeholder="Age"
                      autocomplete="off"
                      @paste.prevent
                      :class="{ 'valid-input': errors.has('form.Age') }"
                      class="input-wrapper__input"
                      required
                    />
                    <validation-message
                      :message="errors.first('form.Age')"
                      :show="errors.has('form.Age')"
                    />
                  </div>

                  <div class="input-wrapper">
                    <input
                      v-model="bookingForm.country"
                      v-validate="{
                        required: true,
                        max: 150
                      }"
                      name="Country"
                      type="text"
                      placeholder="Country"
                      autocomplete="off"
                      @paste.prevent
                      :class="{ 'valid-input': errors.has('form.Country') }"
                      class="input-wrapper__input"
                      required
                    />
                    <validation-message
                      :message="errors.first('form.Country')"
                      :show="errors.has('form.Country')"
                    />
                  </div>

                  <div class="input-wrapper">
                    <input
                      v-model="bookingForm.phone"
                      v-validate="{
                        required: true,
                        numeric: true,
                        min: 6,
                        max: 20
                      }"
                      name="Phone"
                      type="text"
                      placeholder="Phone"
                      autocomplete="off"
                      @paste.prevent
                      :class="{ 'valid-input': errors.has('form.Phone') }"
                      class="input-wrapper__input"
                      required
                    />
                    <validation-message
                      :message="errors.first('form.Phone')"
                      :show="errors.has('form.Phone')"
                    />
                  </div>
                </form>
              </div>
              <div class="flexi-booking__wrapper">
                <div class="flexi-booking__wrapper--item">
                  <label class="label">Arrival</label>
                  <font-awesome-icon
                    @click="openDatePicker('dp3')"
                    icon="sort-down"
                    size="1x"
                  />
                  <datepicker
                    ref="dp3"
                    :bootstrap-styling="true"
                    :full-month-name="true"
                    :disabled-dates="{
                      to: new Date(new Date().getTime() - 6400000)
                    }"
                    :format="'d MMMM yyyy'"
                    @selected="changeDepartureDate"
                    v-model="bookingForm.arrival"
                  />
                </div>
                <div class="flexi-booking__wrapper--item">
                  <label class="label">Departure</label>
                  <font-awesome-icon
                    @click="openDatePicker('dp4')"
                    icon="sort-down"
                    size="1x"
                  />
                  <datepicker
                    ref="dp4"
                    :bootstrap-styling="true"
                    :full-month-name="true"
                    :disabled-dates="{
                      to: new Date(bookingForm.arrival + 6400000)
                    }"
                    :format="'d MMMM yyyy'"
                    v-model="bookingForm.departure"
                  />
                </div>
                <div class="flexi-booking__wrapper--item">
                  <label class="label">Guests</label>
                  <v-select
                    v-if="guestList"
                    v-model="bookingForm.guests"
                    :options="guestList"
                    :reduce="name => name.name"
                    label="name"
                    class="chart-chooser"
                  />
                </div>
                <div class="flexi-booking__wrapper--item">
                  <label class="label">Room Type</label>
                  <v-select
                    v-if="bookingTypes"
                    v-model="bookingForm.type"
                    :options="bookingTypes"
                    :reduce="name => name.name"
                    label="name"
                    class="chart-chooser"
                  />
                </div>
              </div>
              <div class="flexi-booking__wrapper--item btn-item">
                <button
                  class="button"
                  :disabled="processingBooking"
                  @click="reserveRoom"
                >
                  <spring-spinner
                    v-if="processingBooking"
                    :animation-duration="2000"
                    :size="36"
                    color="#fff"
                  />
                  <span v-else class="button__text" v-text="'Reserve'" />
                </button>
              </div>
              <div class="flexi-modal__footer">
                <div class="item">
                  <font-awesome-icon icon="check-circle" size="1x" />
                  <h3>
                    Your room will be ready for check-in between 12:00 PM and
                    8:00 PM, once you get back confirmation email from the
                    Villa!
                  </h3>
                </div>
                <div class="item">
                  <font-awesome-icon icon="envelope" size="1x" />
                  <h3>
                    Confirmation email will be sent to the entered email
                    address. Plase make sure it is valid.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </template>
      </flexi-modal>
      <!-- Footer -->
      <flexi-footer :year="year">
        <template v-slot:footer-bottom>
          <div class="footer__bottom">
            <div class="footer__bottom--item-left">
              <span>© {{ year }} All Rights Reserved </span>
            </div>
            <div class="footer__bottom--item-right">
              <span>
                Made by
                <a
                  class="footer-link"
                  href="https://akrinum.com"
                  target="_blank"
                  >Akrinum OÜ</a
                >
              </span>
            </div>
          </div>
        </template>
      </flexi-footer>
    </div>
    <vue-preloader
      background-color="#6988a7"
      color="#ffffff"
      :loading-speed="15"
      :transition-speed="1400"
    >
    </vue-preloader>
  </div>
</template>
<script>
import { mapFields } from "./store/helpers";
import { mapGetters, mapActions } from "vuex";
import { rightMenu } from "./static/data/rightMenu";

export default {
  computed: {
    ...mapGetters([
      "inputData",
      "isNavOpen",
      "loadingScreen",
      "year",
      "cookieVisible",
      "currentRoute"
    ]),
    ...mapFields(["bookingForm"])
  },
  watch: {
    overflowHidden() {
      document.body.style.overflow = this.overflowHidden ? "hidden" : "";
    },
    $route: {
      handler(to) {
        this.setCurrentRoute(to.name);
      },
      immediate: true
    }
  },
  data() {
    return {
      rightMenu: rightMenu,
      overflowHidden: false
    };
  },
  mounted() {
    this.fetchData();
    this.setYear();
  },
  methods: {
    ...mapActions([
      "setBurger",
      "fetchData",
      "setYear",
      "setLanguage",
      "setCookieVisible",
      "setCurrentRoute"
    ]),
    changeLanguage(language) {
      this.setLanguage(language);
    },
    chooseRedirection(route, isPage, bookPage) {
      if (bookPage === true) {
        location.href = "#booking";
      } else if (isPage === true) {
        this.$router.push(route);
      } else {
        window.open(route, "_blank");
      }
    },
    setInitBurger() {
      this.overflowHidden = !this.overflowHidden;
      this.setBurger();
    },
    clickedLogo() {
      if (this.overflowHidden === true) {
        this.setBurger();
        this.overflowHidden = false;
        this.$router.push("/").catch(() => {});
      } else {
        this.$router.push("/").catch(() => {});
      }
    }
  }
};
</script>
<style lang="scss">
@import "assets/scss/application";
.overlay-left,
.overlay-right,
.overlay-top,
.overlay-bottom,
.overlay-up-down {
  z-index: 10003 !important;
}
</style>
