/*
    If you want to use epic spinner components in the app, 
    just import and register them properly as sample below.
*/

import Vue from "vue";
import {
  HollowDotsSpinner,
  SemipolarSpinner,
  SpringSpinner
} from "epic-spinners";

Vue.component("hollow-dots-spinner", HollowDotsSpinner);
Vue.component("semipolar-spinner", SemipolarSpinner);
Vue.component("spring-spinner", SpringSpinner);
