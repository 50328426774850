<template>
  <div class="flexi-container page__internal page__not-found">
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[7].top_card.title"
      @button-clicked="smoothScroll"
    />

    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="scroll('#top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <flexi-hash />

    <flexi-advanced-last-card
      v-if="inputData.homePage"
      :inputData="inputData.homePage.last_card"
      :navigationPages="inputData.navigationPages"
      :loadingForm="loading"
      @button-clicked="sendNewsEmail"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "page-not-found",
  mounted() {
    this.animateTopCard();
    setTimeout(() => {
      this.smoothScroll("top-card");
    }, 1300);
  },
  computed: {
    ...mapGetters(["inputData"])
  }
};
</script>

<style lang="scss">
// @import "~@schnapsterdog/flexi-vue/src/assets/scss/parts/_media-mixins";
</style>
