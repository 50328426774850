export default {
  inputData: {},
  year: null,
  isNavOpen: false,
  loadingScreen: true,
  language: "EN",
  cookieVisible: false,
  currentRoute: "",
  bookingForm: {
    arrival: new Date(),
    departure: new Date(new Date().getTime() + 586400000),
    guests: 1,
    type: "Studio with Lake View",
    name: "",
    lastName: "",
    email: "",
    age: "",
    country: "",
    phone: ""
  }
};
