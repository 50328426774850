<template>
  <div class="page__internal page__activities">
    <flexi-top-card :inputData="inputData.internalPages[1].top_card">
      <template v-slot:title="slotProps">
        <h1 class="top-card__title">
          <span
            class="top-card__title-item"
            v-text="slotProps.inputData.title"
          />
        </h1>
        <div class="flexi-booking">
          <div class="flexi-booking__wrapper">
            <div class="flexi-booking__wrapper--item">
              <label class="label">Arrival</label>
              <font-awesome-icon
                @click="openDatePicker('dp1')"
                icon="sort-down"
                size="1x"
              />
              <datepicker
                ref="dp1"
                :bootstrap-styling="true"
                :full-month-name="true"
                :disabled-dates="{
                  to: new Date(new Date().getTime() - 6400000)
                }"
                :format="'d MMMM yyyy'"
                @selected="changeDepartureDate"
                v-model="bookingForm.arrival"
              />
            </div>
            <div class="flexi-booking__wrapper--item">
              <label class="label">Departure</label>
              <font-awesome-icon
                @click="openDatePicker('dp2')"
                icon="sort-down"
                size="1x"
              />
              <datepicker
                ref="dp2"
                :bootstrap-styling="true"
                :full-month-name="true"
                :disabled-dates="{
                  to: new Date(bookingForm.arrival + 6400000)
                }"
                :format="'d MMMM yyyy'"
                v-model="bookingForm.departure"
              />
            </div>
            <div class="flexi-booking__wrapper--item">
              <label class="label">Guests</label>
              <v-select
                v-if="guestList"
                v-model="bookingForm.guests"
                :options="guestList"
                :reduce="name => name.name"
                label="name"
                class="chart-chooser"
              />
            </div>
            <div class="flexi-booking__wrapper--item">
              <label class="label">Room Type</label>
              <v-select
                v-if="bookingTypes"
                v-model="bookingForm.type"
                :options="bookingTypes"
                :reduce="name => name.name"
                label="name"
                class="chart-chooser"
              />
            </div>
            <div class="flexi-booking__wrapper--item btn-item">
              <button @click="openBookingModal" class="button">
                <span
                  class="button__text"
                  v-text="inputData.internalPages[1].top_card.buttonText"
                />
              </button>
            </div>
          </div>
        </div>
      </template>
    </flexi-top-card>
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[1].sections[0]"
    >
      <template v-slot:title="slotProps">
        <h2 data-aos="fade-up" data-aos-duration="900" data-aos-offset="300">
          <span v-text="slotProps.inputData.title" />
        </h2>
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content-left">
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
            class="flexi-wrapper__section--content-left--img"
            data-aos="fade-right"
            data-aos-duration="900"
            data-aos-offset="300"
          />
        </div>
        <div class="flexi-wrapper__section--content-right">
          <img
            :src="slotProps.inputData.widgets[0].img"
            class="flexi-wrapper__section--content-right--img"
            data-aos="fade-left"
            data-aos-duration="900"
            data-aos-offset="300"
          />
          <p
            class="flexi-wrapper__section--content-right--paragraph"
            v-html="slotProps.inputData.description"
            data-aos="fade-up"
            data-aos-duration="900"
            data-aos-offset="300"
          />
        </div>
      </template>
    </flexi-wrapper>
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[1].sections[1]"
    >
      <template v-slot:title>
        <h3
          v-text="inputData.internalPages[1].sections[1].title"
          data-aos="fade-up"
          data-aos-duration="900"
          data-aos-offset="300"
        />
        <div class="flexi-pagination">
          <a class="flexi-pagination__back" @click="slidePrev">
            <div class="pagination-button">
              <div
                class="pagination-button__svg"
                :class="{ disabled: disabledPrev }"
              />
            </div>
          </a>
          <a class="flexi-pagination__next" @click="slideNext">
            <div class="pagination-button pagination-button--rotated">
              <div
                class="pagination-button__svg"
                :class="{ disabled: disabledNext }"
              />
            </div>
          </a>
        </div>
      </template>
      <template v-slot:content="slotProps">
        <agile ref="slider" :options="sliderOptions" class="parent-division">
          <div
            v-for="(value, key) in slotProps.inputData.widgets"
            :key="key"
            class="flexi-wrapper__section--content--item"
            @click="blankImg(value.img)"
          >
            <img
              :src="value.img"
              :alt="value.title"
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-offset="200"
              class="flexi-wrapper__section--content--item--img"
            />
            <h4
              v-text="value.title"
              class="flexi-wrapper__section--content--item--title"
            />
          </div>
        </agile>
        <div class="child-division">
          <p
            v-text="slotProps.inputData.contentTitle"
            class="child-division--title"
          />
          <p
            v-text="slotProps.inputData.contentLine"
            class="child-division--subtitle"
          />
          <flexi-button
            @button-clicked="goToRoute(slotProps.inputData.buttonLink)"
            v-text="slotProps.inputData.buttonText"
          />
        </div>
      </template>
    </flexi-wrapper>
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[1].sections[2]"
    >
      <template v-slot:title="slotProps">
        <div class="lastCard-title">
          <a href="/">
            <h2
              v-text="slotProps.inputData.title"
              :data-aos="currentResolution >= 991 ? 'fade-up' : ''"
              data-aos-duration="900"
              data-aos-offset="300"
            />
          </a>
        </div>
        <div v-text="slotProps.inputData.subTitle" class="lastCard-subtitle" />
        <p
          v-html="slotProps.inputData.description"
          :data-aos="currentResolution >= 991 ? 'fade-up' : ''"
          data-aos-duration="900"
          data-aos-offset="300"
          class="flexi-wrapper__section--paragraph"
        />
      </template>
      <template v-slot:content="slotProps">
        <flexi-column
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          :inputData="value"
          :options="lastCardSection.options"
          :data-aos="
            currentResolution >= 991
              ? (key === 0 && 'fade-right') ||
                (key === 1 && 'fade-down') ||
                (key === 2 && 'fade-left')
              : ''
          "
          data-aos-duration="1500"
        />
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import { mapFields } from "../store/helpers";
import { mapGetters } from "vuex";

export default {
  name: "activities",
  computed: {
    ...mapGetters(["inputData"]),
    ...mapFields(["bookingForm"]),
    disabledPrev() {
      return this.currentPage === 0;
    },
    disabledNext() {
      return (
        this.currentPage ===
        this.inputData.internalPages[1].sections[1].widgets.length + 1
      );
    }
  },
  data() {
    return {
      sliderOptions: {
        dots: false,
        navButtons: false,
        slidesToShow: 3
      },
      currentPage: 0,
      lastCardSection: {
        options: {
          img: true,
          date: false,
          description: false,
          paragraphOn: false,
          subTitle: true,
          button: false
        }
      }
    };
  },
  methods: {
    slidePrev() {
      if (this.currentPage > 0) {
        this.$refs.slider.goToPrev();
        setTimeout(() => {
          this.currentPage -= 1;
        }, 305);
      }
    },
    slideNext() {
      if (
        this.currentPage <=
        this.inputData.internalPages[1].sections[1].widgets.length
      ) {
        this.$refs.slider.goToNext();
        setTimeout(() => {
          this.currentPage += 1;
        }, 305);
      }
    },
    blankImg(value) {
      window.open(value);
    },
    onResize() {
      if (window.innerWidth <= 768) {
        this.sliderOptions.slidesToShow = 1;
      } else {
        this.sliderOptions.slidesToShow = 3;
      }
    }
  },
  mounted() {
    this.animateTopCard();
    this.onResize();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style></style>
