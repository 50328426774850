export const rightMenu = [
  // {
  //   name: "Gallery",
  //   href: "/gallery",
  //   isPage: true,
  //   bookPage: false
  // },
  {
    name: "Directions",
    href: "http://www.google.com/maps/place/41.060738, 20.802967",
    isPage: false,
    bookPage: false
  },
  {
    name: "Booking",
    href: "/",
    isPage: false,
    bookPage: true
  },
  {
    name: "Disclaimer",
    href: "/legal-notice",
    isPage: true,
    bookPage: false
  },
  {
    name: "Data Protection",
    href: "/data-protection",
    isPage: true,
    bookPage: false
  }
];
