<template>
  <div class="flexi-container">
    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-top-card :inputData="inputData.homePage.top_card">
      <template v-slot:title="slotProps">
        <h1 class="top-card__title">
          <span
            class="top-card__title-item"
            v-text="slotProps.inputData.title"
          />
        </h1>
        <div class="flexi-booking">
          <div class="flexi-booking__wrapper">
            <div class="flexi-booking__wrapper--item">
              <label class="label">Arrival</label>
              <font-awesome-icon
                @click="openDatePicker('dp1')"
                icon="sort-down"
                size="1x"
              />
              <datepicker
                ref="dp1"
                :bootstrap-styling="true"
                :full-month-name="true"
                :disabled-dates="{
                  to: new Date(new Date().getTime() - 6400000)
                }"
                :format="'d MMMM yyyy'"
                @selected="changeDepartureDate"
                v-model="bookingForm.arrival"
              />
            </div>
            <div class="flexi-booking__wrapper--item">
              <label class="label">Departure</label>
              <font-awesome-icon
                @click="openDatePicker('dp2')"
                icon="sort-down"
                size="1x"
              />
              <datepicker
                ref="dp2"
                :bootstrap-styling="true"
                :full-month-name="true"
                :disabled-dates="{
                  to: new Date(bookingForm.arrival + 6400000)
                }"
                :format="'d MMMM yyyy'"
                v-model="bookingForm.departure"
              />
            </div>
            <div class="flexi-booking__wrapper--item">
              <label class="label">Guests</label>
              <v-select
                v-if="guestList"
                v-model="bookingForm.guests"
                :options="guestList"
                :reduce="name => name.name"
                label="name"
                class="chart-chooser"
              />
            </div>
            <div class="flexi-booking__wrapper--item">
              <label class="label">Room Type</label>
              <v-select
                v-if="bookingTypes"
                v-model="bookingForm.type"
                :options="bookingTypes"
                :reduce="name => name.name"
                label="name"
                class="chart-chooser"
              />
            </div>
            <div class="flexi-booking__wrapper--item btn-item">
              <button @click="openBookingModal" class="button">
                <span
                  class="button__text"
                  v-text="inputData.homePage.top_card.buttonText"
                />
              </button>
            </div>
          </div>
        </div>
      </template>
    </flexi-top-card>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[0]"
    >
      <template v-slot:title="slotProps">
        <h2 data-aos="fade-up" data-aos-duration="900" data-aos-offset="300">
          <span v-text="slotProps.inputData.title" />
        </h2>
        <p
          class="flexi-wrapper__section--paragraph"
          v-html="slotProps.inputData.description"
          data-aos="fade-up"
          data-aos-duration="900"
          data-aos-offset="300"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content-left">
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
            class="flexi-wrapper__section--content-left--img"
            data-aos="fade-right"
            data-aos-duration="900"
            data-aos-offset="300"
          />
        </div>
        <div class="flexi-wrapper__section--content-right">
          <img
            :src="slotProps.inputData.widgets[0].img"
            class="flexi-wrapper__section--content-right--img"
            data-aos="fade-left"
            data-aos-duration="900"
            data-aos-offset="300"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[1]"
    >
      <template v-slot:title="slotProps">
        <h2 data-aos="fade-up" data-aos-duration="900" data-aos-offset="300">
          <span v-text="slotProps.inputData.title" />
        </h2>
        <p
          class="flexi-wrapper__section--paragraph"
          v-html="slotProps.inputData.description"
          data-aos="fade-up"
          data-aos-duration="900"
          data-aos-offset="300"
        />
      </template>
      <template v-slot:content="slotProps">
        <flexi-slider
          :inputData="slotProps.inputData.widgets"
          :turnTimer="false"
          :transitionName="'fade'"
          :transitionMode="'out-in'"
          id="flexi__slider--first"
        >
          <template
            v-slot:header="{ inputData, changeSlide, currentSliderIndex }"
          >
            <div
              v-for="(value, key) in inputData"
              :key="key"
              class="flexi-slider__header--item"
              @click="
                changeSlide(key),
                  currentResolution < 991
                    ? smoothScroll('flexi__slider--first')
                    : ''
              "
            >
              <div
                v-text="value.title"
                class="flexi-slider__header--item__title"
                :class="{ 'active-title': currentSliderIndex === key }"
              />
              <div
                v-html="value.paragraph"
                class="flexi-slider__header--item__paragraph"
                :class="{ 'active-paragraph': currentSliderIndex === key }"
              />
              <a
                class="flexi-slider__header--item__button"
                :class="{ 'active-button': currentSliderIndex === key }"
                v-text="value.buttonText"
                :href="value.buttonLink"
                target="_blank"
              />
            </div>
          </template>
          <template v-slot:item="{ currentItem }">
            <img class="flexi-slider__wrapper--image" :src="currentItem.img" />
          </template>
        </flexi-slider>
      </template>
    </flexi-wrapper>

    <!-- third section slider -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[2]"
      data-aos="fade-up"
      data-aos-duration="900"
      data-aos-offset="300"
    >
      <template v-slot:content="slotProps">
        <flexi-slider
          :inputData="slotProps.inputData.widgets"
          :transitionName="'fade'"
          :transitionMode="'out-in'"
          :nextButtonText="''"
          :prevButtonText="''"
        >
          <template v-slot:item="{ currentItem }">
            <div class="flexi-slider__wrapper--img">
              <img :src="currentItem.img" />
            </div>
          </template>
          <template v-slot:indicator="{ computedIndex }">
            <div class="flexi-slider__wrapper--counter">
              <span v-text="formatNumber(computedIndex)" />
              <span v-text="'/'" />
              <span v-text="formatNumber(slotProps.inputData.widgets.length)" />
            </div>
          </template>
        </flexi-slider>
      </template>
    </flexi-wrapper>

    <!-- fourth section -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[5]"
    >
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content-left">
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
            class="flexi-wrapper__section--content-left--img"
            data-aos="fade-up"
            data-aos-duration="900"
            data-aos-offset="300"
          />
          <img
            :src="slotProps.inputData.widgets[0].img"
            :alt="slotProps.inputData.widgets[0].title"
            class="flexi-wrapper__section--content-left--second--img"
            data-aos="fade-up"
            data-aos-duration="900"
            data-aos-offset="300"
          />
        </div>
        <div class="flexi-wrapper__section--content-right">
          <div class="flexi-wrapper__section--content-right__position">
            <p
              v-text="slotProps.inputData.subTitle"
              class="flexi-wrapper__section--content-right__position--subtitle"
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-offset="300"
            />
            <h2
              v-text="slotProps.inputData.title"
              class="flexi-wrapper__section--content-right__position--title"
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-offset="300"
            />
            <p
              v-html="slotProps.inputData.description"
              class="flexi-wrapper__section--content-right__position--paragraph"
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-offset="300"
            />
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Fifth section slider -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[3]"
    >
      <template v-slot:title="slotProps">
        <h2 data-aos="fade-up" data-aos-duration="900" data-aos-offset="300">
          <span v-text="slotProps.inputData.title" />
        </h2>
        <p
          class="flexi-wrapper__section--paragraph"
          v-html="slotProps.inputData.description"
          data-aos="fade-up"
          data-aos-duration="900"
          data-aos-offset="300"
        />
      </template>
      <template v-slot:content="slotProps">
        <flexi-slider
          :inputData="slotProps.inputData.widgets"
          :turnTimer="false"
          :transitionName="'fade'"
          :transitionMode="'out-in'"
          id="flexi__slider--second"
        >
          <template
            v-slot:header="{ inputData, changeSlide, currentSliderIndex }"
          >
            <div
              v-for="(value, key) in inputData"
              :key="key"
              class="flexi-slider__header--item"
              @click="
                changeSlide(key),
                  currentResolution < 991
                    ? smoothScroll('flexi__slider--second')
                    : ''
              "
            >
              <div
                class="flexi-slider__header--item__title"
                :class="{ 'active-title': currentSliderIndex === key }"
                v-text="value.title"
              />
              <div
                class="flexi-slider__header--item__paragraph"
                :class="{ 'active-paragraph': currentSliderIndex === key }"
                v-html="value.paragraph"
              />
              <div
                class="flexi-slider__header--item__button"
                :class="{ 'active-button': currentSliderIndex === key }"
                v-text="value.buttonText"
              />
            </div>
          </template>
          <template v-slot:item="{ currentItem }">
            <img class="flexi-slider__wrapper--image" :src="currentItem.img" />
          </template>
        </flexi-slider>
      </template>
    </flexi-wrapper>

    <!-- Last Card -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[4]"
    >
      <template v-slot:title="slotProps">
        <div class="lastCard-title">
          <a href="/">
            <h2
              v-text="slotProps.inputData.title"
              :data-aos="currentResolution >= 991 ? 'fade-up' : ''"
              data-aos-duration="900"
              data-aos-offset="300"
            />
          </a>
        </div>
        <div class="lastCard-subtitle" v-text="slotProps.inputData.subTitle" />
        <p
          class="flexi-wrapper__section--paragraph"
          v-html="slotProps.inputData.description"
          :data-aos="currentResolution >= 991 ? 'fade-up' : ''"
          data-aos-duration="900"
          data-aos-offset="300"
        />
      </template>
      <template v-slot:content="slotProps">
        <flexi-column
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          :inputData="value"
          :options="lastCardSection.options"
          :data-aos="
            currentResolution >= 991
              ? (key === 0 && 'fade-right') ||
                (key === 1 && 'fade-down') ||
                (key === 2 && 'fade-left')
              : ''
          "
          data-aos-duration="1500"
        />
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import { mapFields } from "../store/helpers";
import { mapGetters } from "vuex";

export default {
  name: "home",
  computed: {
    ...mapGetters(["inputData"]),
    ...mapFields(["bookingForm"])
  },
  data() {
    return {
      currentResolution: 0,
      firstSection: {
        options: {
          img: true,
          date: false,
          description: false,
          paragraphOn: false,
          subTitle: false,
          button: false
        }
      },
      lastCardSection: {
        options: {
          img: true,
          date: false,
          description: false,
          paragraphOn: false,
          subTitle: true,
          button: false
        }
      }
    };
  },
  mounted() {
    this.animateTopCard();
    this.currentResolution = window.innerWidth;
    window.addEventListener("resize", () => {
      this.currentResolution = window.innerWidth;
    });
    setTimeout(() => {
      this.smoothScroll("top-card");
    }, 1300);
  }
};
</script>

<style lang="scss">
// @import "~@schnapsterdog/flexi-vue/src/assets/scss/parts/_media-mixins";
</style>
