/*
    <vue-page-transition name="fade-in-right">
      <router-view />
    </vue-page-transition>
    See more at: https://github.com/Orlandster/vue-page-transition
*/

import Vue from "vue";
import VuePageTransition from "vue-page-transition";

Vue.use(VuePageTransition);
