<template>
  <div :id="name" class="overlay-modal">
    <a href="#" class="cancel-button" />
    <div class="flexi-modal">
      <slot name="content">
        <div class="flexi-modal__content">
          <slot name="modal-content">
            <div :id="currentIndex" v-if="modalData">
              <img class="flexi-modal__content--img" :src="modalData.img" />
              <h2
                class="flexi-modal__content--title"
                v-text="modalData.title"
              />
              <div
                class="flexi-modal__content--paragraph"
                v-html="modalData.paragraph"
              />
              <div class="last-card__social-icons social-icons">
                <ShareNetwork
                  v-for="network in networks"
                  :network="network.network"
                  :key="network.network"
                  :url="sharing.url + modalData.buttonLink"
                  :title="modalData.title"
                  :description="modalData.description"
                  :quote="modalData.fbQuote"
                  :hashtags="modalData.hashtags"
                  :twitterUser="sharing.twitterUser"
                >
                  <i class="icon" :class="network.icon" />
                </ShareNetwork>
              </div>
              <div class="flexi-modal__content--pagination">
                <a class="prev" @click="prev">
                  <div class="pagination__button">
                    <div class="svg" :class="{ disabled: disabledPrev }" />
                  </div>
                </a>
                <a class="next" @click="next">
                  <div class="pagination__button pagination__button--rotated">
                    <div class="svg" :class="{ disabled: disabledNext }" />
                  </div>
                </a>

                <a href="#" class="close" />
              </div>
            </div>
          </slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    modalData: {
      type: Object
    },
    modalArray: {
      type: Array
    },
    currentIndex: {
      type: Number
    }
  },

  data() {
    return {
      sharing: {
        url: "https://akrinum.com",
        twitterUser: "akrinum"
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "ion-logo-facebook",
          color: "#1877f2"
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "ion-logo-twitter",
          color: "#1da1f2"
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "ion-logo-linkedin",
          color: "#007bb5"
        }
      ]
    };
  },

  computed: {
    disabledPrev() {
      return this.currentIndex === 0;
    },
    disabledNext() {
      return this.currentIndex === this.modalArray.length - 1;
    }
  },

  methods: {
    setModalName(name) {
      return (location.hash = name);
    },
    next() {
      if (this.currentIndex < this.modalArray.length - 1) {
        this.smoothScroll(this.currentIndex);
        this.currentIndex += 1;
        this.modalArray.filter((element, key) => {
          if (key === this.currentIndex) {
            this.modalData = element;
          }
        });
      }
    },
    prev() {
      if (this.currentIndex > 0) {
        this.smoothScroll(this.currentIndex);
        this.currentIndex -= 1;
        this.modalArray.filter((element, key) => {
          if (key === this.currentIndex) {
            this.modalData = element;
          }
        });
      }
    },
    smoothScroll(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay-modal {
  -webkit-font-smoothing: antialiased;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s;
  opacity: 0;
  z-index: 9999;
}

.overlay-modal:target {
  visibility: visible;
  opacity: 1;
}

.overlay-modal *,
.overlay-modal *:before,
.overlay-modal *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.flexi-modal {
  position: relative;
  width: 55%;
  height: 75%;
  background: white;
  border-radius: 4px;
  box-shadow: 0 5px 11px rgba(36, 37, 38, 0.08);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 700px) {
    width: 100%;
    height: 100%;
  }

  &__content {
    overflow: auto;
    padding: 40px;

    &--img {
      width: 100%;
    }

    &--title {
      margin-top: 10px;
      font-size: 38px;
      font-weight: bold;

      @media only screen and (max-width: 700px) {
        font-size: 28px;
      }
    }

    &--paragraph {
      position: relative;
      top: 20px;
      color: #268fb4;
      font-size: 22px;
      font-weight: 600;

      @media only screen and (max-width: 700px) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .social-icons {
      text-align: right;
      margin: 0;
      margin-top: 91px;
      a {
        color: #d4145a;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &--pagination {
      .prev,
      .next {
        cursor: pointer;
        position: absolute;
        top: 2%;
        width: auto;
        padding: 16px;
        background-color: transparent;
        color: #ffffff;
        font-weight: bold;
        font-size: 18px;
        transition: 0.7s ease;
        border-radius: 0 4px 4px 0;
        text-decoration: none;
        user-select: none;
      }

      .prev {
        right: 0;
        margin-right: -46px;

        @media only screen and (max-width: 700px) {
          margin-right: 34px;
        }
      }

      .next {
        right: 0;
        margin-right: -5px;
        top: 13%;

        @media only screen and (max-width: 700px) {
          margin-right: 74px;
          top: 12%;
        }
      }

      .close {
        display: none;
        @media only screen and (max-width: 700px) {
          display: block;
          position: absolute;
          right: 21px;
          top: 165px;
          width: 32px;
          height: 32px;
          opacity: 0.3;
        }
      }
      .close:hover {
        opacity: 1;
      }
      .close:before,
      .close:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 33px;
        width: 2px;
        background-color: #d4145a;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }

      .prev:active,
      .next:active {
        background-color: transparent;
      }

      .pagination__button {
        width: 100px;
        top: 0;
        left: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        cursor: pointer;
        // transform: rotate(180deg);

        .text {
          position: relative;
          right: -2.75vw;
          top: -21px;
          color: black;
          transform: rotate(90deg);
          transform-origin: left top 0;
          font-size: 20px;
          transition: all 1s;
        }

        .svg {
          position: relative;
          cursor: pointer;
          width: 60px;
          height: 60px;
          float: right;
          transform: rotate(90deg);
          background: #d4145a;
          border-radius: 0;
          &:before {
            width: 30px;
            height: 2px;
            margin: auto;
            display: block;
            content: "";
            position: absolute;
            top: 1px;
            left: 4px;
            right: 0;
            bottom: 0;
            z-index: 2;
            background: white;
            transition: all 0.7s;
          }

          &:after {
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-right: 13px solid white;
            margin: auto;
            display: block;
            content: "";
            position: absolute;
            top: 1px;
            left: 15px;
            bottom: 0;
            z-index: 2;
            transition: all 0.7s;
          }
        }

        &:hover {
          opacity: 0.8;

          .text {
            top: 20px;
          }

          .svg {
            &:before {
              right: 8px;
            }

            &:after {
              left: 10px;
            }
          }
        }

        .disabled {
          opacity: 0.7;
        }
      }

      .pagination__button--rotated {
        transform: rotate(180deg);
      }
    }
  }
}

.overlay-modal .cancel-button {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
